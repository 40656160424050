@font-face {
  font-family: "HelveticaNeue";
  src: url("./fonts/helveticaneue/HelveticaNeue.ttf") format("truetype"),
    url("./fonts/helveticaneue/Helvetica Neu Bold.ttf") format("truetype"),
    url("./fonts/helveticaneue/HelveticaNeue BlackCond.ttf") format("truetype"),
    url("./fonts/helveticaneue/HelveticaNeue Light.ttf") format("truetype"),
    url("./fonts/helveticaneue/HelveticaNeue Medium.ttf") format("truetype"),
    url("./fonts/helveticaneue/HelveticaNeueHv.ttf") format("truetype"),
    url("./fonts/helveticaneue/HelveticaNeueHv.ttf") format("truetype"),
    url("./fonts/helveticaneue/HelveticaNeue Thin.ttf") format("truetype");
}

@font-face {
  font-family: "helveticaneuebold";
  src: url("./fonts/helveticaneue_font/helvetica_neu_bold-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneueblackcond";
  src: url("./fonts/helveticaneue_font/helveticaneue_blackcond-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneuelight";
  src: url("./fonts/helveticaneue_font/helveticaneue_light-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneuemedium";
  src: url("./fonts/helveticaneue_font/helveticaneue_medium-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneuethin";
  src: url("./fonts/helveticaneue_font/helveticaneue_thin-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneueregular";
  /* src: url('./fonts/helveticaneue_font/helveticaneue-webfont.woff') format('woff'); */
  /* src: url('./fonts/helveticaneue_font/HelveticaNeueLT-55Rm.woff') format('woff'); */
  src: url("./fonts/helveticaneue_font/HelveticaNeueCyr-Roman.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneuebold";
  src: url("./fonts/helveticaneue_font/helveticaneuebd-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneueheavy";
  src: url("./fonts/helveticaneue_font/helveticaneuehv-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneueitalic";
  src: url("./fonts/helveticaneue_font/helveticaneueit-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneuelight";
  src: url("./fonts/helveticaneue_font/helveticaneuelt-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneuemedium";
  src: url("./fonts/helveticaneue_font/helveticaneuemed-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helveticaneuecondensed";
  src: url("./fonts/helveticaneue_font/HelveticaNeue-CondensedRegular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
