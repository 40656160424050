.main table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
.main th, .main td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.main th {
  background-color: #f2f2f2;
}
.main .icon_image {
  max-width: 100px;
  max-height: 100px;
}/*# sourceMappingURL=style.css.map */