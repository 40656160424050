body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: normal 14px "helveticaneueregular" !important;
  letter-spacing: 0.5px !important;
  line-height: 1.5;
  text-align: start;
}

::placeholder {
  color: #6c757d;
  opacity: 1;
}

.wrapper {
  display: flex;
}

[dir="rtl"] p {
  padding-right: 8px;
}
[dir="rtl"] label {
  padding-right: 8px;
}
[dir="rtl"] span {
  padding-right: 8px;
}
[dir="rtl"] input {
  padding-right: 5px;
}

.wrapper {
  .content-wrapper {
    width: 100%;
    overflow-x: hidden;
    background-color: #f8f8f8;
    position: relative;

    .content {
      flex: 1 0 auto;
    }
  }
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  border: none;
  border-radius: 0.25rem;
  white-space: nowrap;
  vertical-align: middle;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #c1c1c1;
  right: 0px;
  background-color: transparent;
}

.icon-place-holder {
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  cursor: pointer;
}

.eep-input-group {
  width: calc(100% - 15%);
  position: relative;
}

.eep_scroll_y {
  overflow-y: scroll !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.align-items-center {
  align-items: center;
}

.form-control {
  font-size: 14px;
  height: calc(1.5em + 0.75rem + 2px);
}

.error-text {
  color: #b40e0e;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  top: auto;
  padding: 0;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

.eep_required_label:after,
.form-group.required .control-label:after {
  content: "*";
  color: red;
}

.urm_droup_inner_div {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

#loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d5d5d578;
  z-index: 3;

  #loader {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

    img {
      width: 100px;
      height: 100px;
    }
  }
}

#page-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d5d5d578;
  z-index: 3;

  #loader {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

    img {
      width: 100px;
      height: 100px;
    }
  }
}

.table-responsive {
  // padding: 0px !important;
  .MuiPaper-root {
    box-shadow: none;
  }
}

.MuiTableCell-head {
  color: #004465 !important;
  text-transform: uppercase !important;
  /* Add your desired styles for the table header */
}

.no_access {
  margin: auto;
  display: flex;
  position: absolute;
  left: 30%;
  top: 40%;
}

// .quill {
//   position: relative;
//   height: 80px;
// }

// .ql-editor{
//   border-top: 1px solid #ccc;
// }
// .ql-toolbar {
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   transform: translateY(100%);
// }

.ql-container {
  min-height: 100px;
}
