/*** HashTag ***/
#hastagColor {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 10px;
}
.hasChooseClr {
  display: flex;
  align-items: center;
  margin-left: 8px;
  position: relative;
  background-color: #ffffff;
  padding: 4px 14px !important;
  border-radius: 2rem;
  border: 1px solid #5c5c5c;
}
#visualcolor,
.hastag-color-inner {
  font-family: "helveticaneuemedium";
  background-color: #28accc;
  color: #ffffff !important;
  text-transform: uppercase;
  border-radius: 40px !important;
  // padding: 10px 32px !important;
  letter-spacing: 1.6px !important;
  display: inline-block;
  border-color: transparent !important;
  font-size: 14px !important;
}
.hastag-color-inner {
 padding: 10px 32px !important;
}
#visualcolor {
  width: 25px;
  height: 25px;
  margin-right: 8px;
}
.eep-templates-setting-title svg:hover .path_bg,
.eep-templates-setting-title svg:hover .path_border,
.textarea_active svg:hover .path_bg,
.textarea_active svg:hover .path_border {
  fill: #f4f4f4;
}
/*** HashTag ***/
