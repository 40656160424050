.category {
  overflow-x: scroll;
  margin-bottom: 12px;
  display: -webkit-box;
  padding: 0;
  margin: 18px 2px 14px;

  .category_button {
    padding: 10px;
    border-radius: 7px;
    background: #f3f3f3;
    margin: 0px 4px 0px 0px;
    cursor: pointer;
    width: max-content !important;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .select {
    background: #000f3f;
    color: #fff;
  }
}

.search_input {
  max-width: 286px;
  margin-bottom: 22px;
}



.list-item {
  padding: 0.5em;
  width: 100%;

  .left {
    inset: 0 auto auto 0;
    transform-origin: 100% 0;
    transform: translate(-29.3%) rotate(-45deg);
  }

  .right {
    inset: 0 0 auto auto;
    transform-origin: 0 0;
    transform: translate(29.3%) rotate(45deg);
  }


  .list-content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 12px;
    // padding: 0px 4px 0px;
    // min-height: 170px;
    overflow: hidden;
    border: 1px solid #E6E6E6;
    .titlesx {
      font-size: 14px;
      margin-top: 10px;
      padding: 0px 10px;
    }

    .content {}

    .discription {
      flex: 1 0 auto;
      font-size: 12px !important;
      opacity: 0.8;
      margin-top: 10px;
    }
  }
}


.coupon-container {
  background-color: #fff;
  margin-top: 15px;
  text-align: center;
}

.coupon-code {
  border-radius: 6px;
  box-shadow: #0000 0px 2px 10px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  background-color: #85879626;
  margin: auto;
  margin-bottom: 15px;
  padding: 4px 10px;
  width: max-content;
}




.copy-button {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: end;

  & input {
    height: 100%;
    border: none;
    outline: none;
    font-size: 15px;
  }

  & button {
    padding: 5px 20px;
    background-color: #fff;
    color: #fff;
    border: 1px solid transparent;
  }
}


.expiration-date {
  font-size: 12px;
  color: #777;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

.reedem {
  .modalBodyHeight {
    text-align: start;
  }

  .modal-confirm {
    width: 480px;

    .modal-footer {
      padding: 0px;
    }
  }


  .modal-content {
    .eep-btn {
      border-radius: 12px !important;
    }

    .eep-btn-success::before {
      border-radius: 12px !important;
    }

    .eep-btn-success::after {
      border-radius: 12px !important
    }

    .field-input {
      border: 1px solid #d4d4d4 !important
    }
  }

}

.field-input-outline {
  border-bottom: 1px solid #d4d4d4 !important;
  border-radius: unset;
}

.min-max {
  font-size: 10px;
  margin-top: 6px;
  float: right
}

.details-titlesx {
  font-size: 18px;
  margin-bottom: 16px;
}

.details-img {
  width: 100%;
  height: 160px;
  margin-bottom: 12px
}