.react-datepicker {
    border: 1px solid #e3e6f0;
}

.react-datepicker__header {
    border-bottom: 1px solid #e3e6f0;
}

.eep-month-picker .react-datepicker__monthPicker .react-datepicker__month-wrapper .react-datepicker__month-text {
    line-height: 45px;
    cursor: pointer;
    border-radius: 4px;
}

.month-picker {
    border: 1px solid #f5f5f5 !important;
}

.eep-month-picker .react-datepicker__day--keyboard-selected,
.eep-month-picker .react-datepicker__month-text--keyboard-selected,
.eep-month-picker .react-datepicker__quarter-text--keyboard-selected,
.eep-month-picker .react-datepicker__year-text--keyboard-selected {
    border-radius: .3rem;
    background-color: #9dc7b2;
    color: #fff;
}

.eep-month-picker .react-datepicker__month-text.react-datepicker__month--selected:hover,
.eep-month-picker .react-datepicker__month-text.react-datepicker__month--in-range:hover,
.eep-month-picker .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.eep-month-picker .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.eep-month-picker .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.eep-month-picker .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.eep-month-picker .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.eep-month-picker .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: #9dc7b2;
}

.eep-timer-parent .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 160px;
}

.eep-timer-parent .react-datepicker__time-container {
    width: 160px;
}

.eep-timer-parent .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    height: 80px;
    overflow-y: scroll !important;
    -ms-overflow-style: none !important;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */
    overflow-x: hidden !important;
}

.eep-timer-parent .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar {
    display: none;
}

.eep-timer-parent .time-individual {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eep-timer-parent .time-individual:hover {
    border-radius: .3rem !important;
}

.eep-timer-parent .time-individual.react-datepicker__time-list-item--selected,
.eep-timer-parent .time-individual.react-datepicker__time-list-item--selected:hover {
    border-radius: .3rem !important;
    background-color: #9dc7b2 !important;
    color: #fff !important;
}

.eepCustomDatepicker .react-datepicker-wrapper {
    width: 100%;
}