#CreateEditCommunicationModal textarea.communication-title {
    color: #2C2C2C !important;
    font-family: 'helveticaneuemedium';
    padding: 10px;
    font-size: 1rem !important;
    outline: none;
    font-weight: 400;
    line-height: 1;
    background-clip: padding-box;
    background-color: #f5f5f5;
    border-radius: 7px;
}

#CreateEditCommunicationModal .checkbox_input {
    margin-right: 5px;
}

.communication_modal_attachement_dflex {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.communication_modal_attachement_dflex_image {
    width: 58px;
    height: 63px;
}

.communication_modal_attachement_dflex img.attachments_add {
    width: 58px;
    height: 63px;
}

.attachments_list_whole_div .attachments_list {
    width: 3.9375rem;
}

#CreateEditCommunicationModal .communication-modal-textarea {
    width: 100%;
    resize: none;
    background-color: #F5F5F5;
    outline: none;
    border: none;
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 7px;
}

#CreateEditCommunicationModal .communication-modal-textarea:focus {
    outline: none;
}

#CreateEditCommunicationModal .pll-4 {
    padding-left: 38px;
}

.communication_add_action_div {
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    background-color: #FFFFFF;
    padding: 15px 0px;
}