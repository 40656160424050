/* 
PROJECT: EEE
VERSION: 0.1
DATE CREATED: 20.01.2021
AUTHOR: TECKONNECT
*/
a {
    opacity: .8;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
}

a:hover {
    opacity: 1;
}

.form-control-lg {
    border-radius: 10px;
}

textarea {
    width: 100%;
    background-color: #F4F4F4;
    border: 1px solid #F4F4F4;
    border-radius: 18px;
}

textarea:focus {
    border: 1px solid #ddd;
    outline: none;
}

.strong {
    font-weight: 500;
}

.border-2 {
    border-width: 2px !important;
}

.icon-18 {
    width: auto;
    height: 12px;
}

.icon-24 {
    width: auto;
    height: 18px;
}

.icon-30 {
    width: auto;
    height: 30px;
}

.dropdown-item>img {
    width: 18px;
    margin-right: 10px;
}

img.icon-30+h6 {
    font-size: 12px;
    letter-spacing: 1px;
    color: #727272;
    font-weight: 600;
}

.rounded-25 {
    border-radius: 25px;
}

.rounded-left-30 {
    border-radius: 0 0 0 30px;
}

.survey-title {
    font-size: 1.5rem !important;
    min-height: 38px;
    font-family: 'helveticaneuemedium';
    background-color: unset;
}

textarea.survey-title {
    color: #2C2C2C !important;
    resize: none;
}

.qa-block {
    position: relative;
}

.sorter {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    width: 24px;
    margin: auto;
}

.options-dropdown,
.duplicate-block,
.delete-block {
    position: absolute;
    right: 1.5rem;
    bottom: 62px;
}

.delete-block {
    bottom: 24px;
}

.duplicate-block>a>i {
    font-size: 20px;
    color: #DBDBDB
}

.duplicate-block>a:hover,
.delete-block>a:hover {
    background: rebeccapurple;
    padding: 5px 7px;
    border-radius: 30px;
    display: block;
    transition: all .2s ease-in-out;
    ;
}

.options-dropdown {
    top: 1.5rem;
    right: 1.95rem;
}

.survey-sidebar {
    position: relative;
    background-color: #EBEBEB;
    border-radius: 20px 0 0 20px;
    right: 0px;
    height: calc(100vh - var(--header-height) - var(--breadcrumb-height) - var(--content-padding-top) - var(--eep-page-header-height) - var(--eep-divider) - 10px);
    padding: 1rem 1.75rem;
}

#eep-sidebar-handle {
    width: 5px;
    height: 50px;
    background-color: #a0a0a038;
    position: absolute;
    left: 7px;
    top: 0;
    border-radius: 12px;
    bottom: 0;
    margin: auto;
    z-index: 2;
}

.sidebar-box-title {
    font-size: 14px;
    padding-top: 8px;
    color: #404040;
}

.question>h4,
ul.anslist>li {
    font-size: 16px;
    color: #2C2C2C;
}

.question>h4 {
    font-size: 18px;
}

ul.anslist {
    margin: 0;
    padding: 0;
}

ul.anslist>li {
    list-style: none;
    position: relative;
    padding: 7.5px 25px;
    width: 50%;
}

.col-md-3 ul.anslit>li,
.col-md-4 ul.anslit>li,
.col-md-6 ul.anslit>li {
    width: 100%;
}

ul.anslist>li>span.delete {
    display: none;
}

ul.anslist>li:hover {
    background-color: rgb(240 239 239 / 58%);
    border-radius: 30px;
    padding-right: 20%;
    cursor: pointer;
    width: 50%;
    transition: 0.3s;
}

ul.anslist>li:hover>span.delete {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 11px;
}

ul.anslist>li:hover>span.delete,
ul.anslist>li:hover>span.delete>a {
    color: #DBDBDB;
}

ul.anslist.row li:hover {
    background-color: transparent;
    padding-right: 0;
    border-radius: 0;
}

.create-question {
    font-size: 25px;
}

.create-question>a>i {
    font-size: 48px;
    color: #C9C9C9;
    vertical-align: middle;
}

.create-question span {
    color: #2C2C2C;
    line-height: 48px;
    padding-left: 20px;
}

.survey-sidebar a {
    color: #404040;
}

.sidebar-box {
    border-radius: 20px;
    text-align: center;
    color: #FFFFFF;
    width: auto;
    max-width: 65px;
    padding: 15px;
    margin: auto;
}

.sidebar-box h5 {
    font-size: 14px;
    color: #E85858;
    font-weight: 600;
    line-height: 25px;
}

.round-wave,
.round {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
}

.round>*,
.circle-ripple>* {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.round {
    background-color: #FFFFFF;
}

.bg-red {
    background-color: #F24F4F;
}

.bg-orange {
    background-color: #FAAC50;
}

.bg-dark-orange {
    background-color: #DE6B24;
}

.bg-light-green {
    background-color: #7CBF9E;
}

.bg-blue-green {
    background-color: #109BB4;
}

.bg-light-blue {
    background-color: #6CB0DB;
}

.btn-dark-orange {
    color: #fff;
    background-color: #DE6B24;
    border-color: #DE6B24;
    padding: 10px 20px;
}

.btn-dark-orange:hover {
    color: #FFFFFF;
    background-color: green;
}

.rounded {
    border-radius: 25px !important;
}

.round-wave {
    -webkit-animation: wait 0s alternate infinite;
    animation: wait 0s alternate infinite;
}

.circle-ripple {
    background-color: #FFFFFF;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 5px;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
}

.pending-survey:hover,
#eep-sidebar-handle:hover,
.fav-star-icon img:hover {
    cursor: pointer;
}

.pending-survey-container {
    position: absolute;
    top: 110px;
    left: 0px;
    padding: 15px 30px;
    background-color: #EBEBEB;
    border-radius: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.pending-survey-notify {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-bottom: 20px;
    background-color: #FFFFFF;
}

.notification-batch {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fc5454;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    color: white;
}

.pending-batch-line {
    width: 20px;
    height: 5px;
    background-color: #fc5454;
    position: absolute;
    left: 10px;
    top: 40px;
}

.pending-notification-title {
    font-size: 14px;
    text-align: left;
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: black;
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.result-list .row {
    border-radius: 10px;
}

.result-list [class^="col"] {
    padding: 16px;
}

.result-list .question-list {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

ul.qb-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.qb-list>li {
    border-radius: 10px;
    padding: 0px 10px;
}

ul.qb-list li:nth-child(odd) {
    background-color: #F0EFEF;
}

.result-list .row {
    border-radius: 10px;
}

.result-list [class^="col"] {
    padding: 20px;
}

/* CUSTOM CHECKBOX - RADIO */
.checkbox label:after,
.radio label:after {
    content: '';
    /* display: table; */
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    /* border-radius: .25em; */
    width: 25px;
    height: 25px;
    line-height: 1.5;
    margin-right: 1rem;
    text-align: center;
    vertical-align: unset;
    color: #A0A0A0;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    line-height: 0;
    top: 50%;
    left: 13%;
}

.radio .cr>i {
    margin-left: .5px;
}

.checkbox label,
.radio label {
    margin-bottom: 0px;
}

.checkbox input,
.radio input {
    display: none;
}

.checkbox label input[type="checkbox"]+.cr>i,
.radio label input[type="radio"]+.cr>i {
    opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>i,
.radio label input[type="radio"]:checked+.cr>i {
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr,
.radio label input[type="radio"]:disabled+.cr {
    opacity: .5;
}

.survey-sidebar a:hover {
    text-decoration: none;
}

@-webkit-keyframes wait {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes wait {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 355, 0.3), 0 0 0 .6em rgba(255, 255, 355, 0.3);
    }

    100% {
        box-shadow: 0 0 0 .4em rgba(255, 255, 355, 0.3), 0 0 0 .8em rgba(255, 255, 355, 0.3), 0 0 0 .9em rgba(101, 255, 120, 0);
    }
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 355, 0.3), 0 0 0 .6em rgba(255, 255, 355, 0.3);
    }

    100% {
        box-shadow: 0 0 0 .4em rgba(255, 255, 355, 0.3), 0 0 0 .8em rgba(255, 255, 355, 0.3), 0 0 0 .9em rgba(101, 255, 120, 0);
    }
}

/* RESPONSIVE CSS */
@media (max-width: 767px) {
    ul.anslist>li:hover {
        width: 100%;
    }
}