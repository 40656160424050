.react-flow {
    height: 88% !important;
}

.react-flow .inner {
    box-shadow: 0px 3px 10px #00000029;
    background: #fff;
    border: 1px solid #9e9e9e26;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    position: relative;
}

.react-flow .icon {
    margin-right: 8px;
}

.react-flow .icon .img {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    margin-top: -6px !important;
}

.react-flow .body {
    padding: 20px 10px 4px;
    display: flex;
}

.react-flow .title {
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 1;
    font-weight: bold;
    color: #000 !important;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px !important;
}

.react-flow .subline {
    font-size: 11px;
    color: #777;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px !important;
}

.react-flow .footer {
    background-color: #F0F0F0;
    width: 100% !important;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    padding: 6px 0px;
    cursor: pointer !important;
}

.react-flow .footer .img {
    margin-top: -1px;
    margin-right: 2px;
}

.react-flow .cloud {
    width: 30px;
    height: 30px;
    right: 0;
    position: absolute;
    /* z-index: 10000000000000000; */
    top: -2px;
    /* transform: translate(50%, -50%); */
    display: flex;
    transform-origin: center center;
    padding: 2px;
    overflow: hidden;
    cursor: pointer !important;
    z-index: 1;
}

.zoomable {
    height: 60px;
    position: fixed;
    bottom: 10px;
}

.zoomable div {
    border: 1px solid #9e9e9ec4;
    padding: 8px;
    cursor: pointer;
}
.react-flow__handle{
    background: transparent;
    border: none;
}

.react-flow__attribution{
    visibility: hidden !important;
}