a {
    opacity: .8;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
}

a:hover {
    opacity: 1;
}

.form-control-lg {
    border-radius: 10px;
}

textarea {
    width: 100%;
    background-color: #F4F4F4;
    border: 1px solid #F4F4F4;
    border-radius: 18px;
}

textarea:focus {
    border: 1px solid #ddd;
    outline: none;
}

.strong {
    font-weight: 500;
}

.border-2 {
    border-width: 2px !important;
}

.icon-18 {
    width: auto;
    height: 12px;
}

.icon-24 {
    width: auto;
    height: 18px;
}

.icon-30 {
    width: auto;
    height: 30px;
}

.dropdown-item>img {
    width: 18px;
    margin-right: 10px;
}

img.icon-30+h6 {
    font-size: 12px;
    letter-spacing: 1px;
    color: #727272;
    font-weight: 600;
}

.rounded-25 {
    border-radius: 25px;
}

.rounded-left-30 {
    border-radius: 0 0 0 30px;
}

.poll-title {
    color: #2C2C2C !important;
    font-size: 1.5rem !important;
    min-height: 38px;
    font-family: 'helveticaneuemedium';
}

textarea.poll-title {
    resize: none;
    background-color: #f5f5f5;
    padding: 7px 15px !important;
    border-radius: 10px;
}

.qa-block {
    position: relative;
}

.sorter {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    width: 24px;
    margin: auto;
}

.options-dropdown,
.duplicate-block,
.delete-block {
    position: absolute;
    right: 1.5rem;
    bottom: 62px;
}

.delete-block {
    bottom: 24px;
}

.duplicate-block>a>i {
    font-size: 20px;
    color: #DBDBDB
}

.duplicate-block>a:hover,
.delete-block>a:hover {
    background: rebeccapurple;
    padding: 5px 7px;
    border-radius: 30px;
    display: block;
    transition: all .2s ease-in-out;
    ;
}

.options-dropdown {
    top: 1.5rem;
    right: 1.95rem;
}

.poll-sidebar {
    position: relative;
    background-color: #EBEBEB;
    border-radius: 20px 0 0 20px;
    right: 0px;
    height: calc(100vh - var(--header-height) - var(--breadcrumb-height) - var(--content-padding-top) - var(--eep-page-header-height) - var(--eep-divider) - 10px);
    padding: 1rem 1.75rem;
}

.eep-sidebar .sidebar-item {
    width: 106px;
}

#poll-sidebar-handle {
    width: 5px;
    height: 50px;
    background-color: #a0a0a038;
    position: absolute;
    left: 7px;
    top: 0;
    border-radius: 12px;
    bottom: 0;
    margin: auto;
    z-index: 2;
}

.sidebar-box-title {
    font-size: 14px;
    padding-top: 8px;
    color: #404040;
}

.question>h4,
ul.anslist>li {
    font-size: 16px;
    color: #2C2C2C;
}

.question>h4 {
    font-size: 18px;
}

ul.anslist {
    margin: 0;
    padding: 0;
}

ul.anslist>li {
    list-style: none;
    position: relative;
    padding: 7.5px 25px;
    width: 50%;
}

.col-md-3 ul.anslit>li,
.col-md-4 ul.anslit>li,
.col-md-6 ul.anslit>li {
    width: 100%;
}

ul.anslist>li>span.delete {
    display: none;
}

ul.anslist>li:hover {
    background-color: rgb(240 239 239 / 58%);
    border-radius: 30px;
    padding-right: 20%;
    cursor: pointer;
    width: 50%;
    transition: 0.3s;
}

ul.anslist>li:hover>span.delete {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 11px;
}

ul.anslist>li:hover>span.delete,
ul.anslist>li:hover>span.delete>a {
    color: #DBDBDB;
}

ul.anslist.row li:hover {
    background-color: transparent;
    padding-right: 0;
    border-radius: 0;
}

.create-question {
    font-size: 25px;
}

.create-question>a>i {
    font-size: 48px;
    color: #C9C9C9;
    vertical-align: middle;
}

.create-question span {
    color: #2C2C2C;
    line-height: 48px;
    padding-left: 20px;
}

.poll-sidebar a {
    color: #404040;
}

.sidebar-box {
    border-radius: 20px;
    text-align: center;
    color: #FFFFFF;
    width: auto;
    max-width: 65px;
    padding: 15px;
    margin: auto;
}

.sidebar-box h5 {
    font-size: 14px;
    color: #E85858;
    font-weight: 600;
    line-height: 25px;
}

.round-wave,
.round {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
}

.round>*,
.circle-ripple>* {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.round {
    background-color: #FFFFFF;
}

.bg-red {
    background-color: #F24F4F;
}

.bg-orange {
    background-color: #FAAC50;
}

.bg-dark-orange {
    background-color: #DE6B24;
}

.bg-light-green {
    background-color: #7CBF9E;
}

.poll-sidebar .bg-blue-green {
    background-color: #1076B4;
}

.bg-light-blue {
    background-color: #6CB0DB;
}

.btn-dark-orange {
    color: #fff;
    background-color: #DE6B24;
    border-color: #DE6B24;
    padding: 10px 20px;
}

.btn-dark-orange:hover {
    color: #FFFFFF;
    background-color: green;
}

.rounded {
    border-radius: 25px !important;
}

.round-wave {
    -webkit-animation: wait 0s alternate infinite;
    animation: wait 0s alternate infinite;
}

.circle-ripple {
    background-color: #FFFFFF;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 5px;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
}

.pending-poll:hover,
#poll-sidebar-handle:hover,
.fav-star-icon img:hover {
    cursor: pointer;
}

.pending-poll-container {
    position: absolute;
    top: 110px;
    left: 0px;
    padding: 15px 30px;
    background-color: #EBEBEB;
    /* box-shadow: 1px 1px 20px 1px rgb(0 0 0 / 20%); */
    border-radius: 30px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.pending-poll-notify {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-bottom: 20px;
    background-color: #FFFFFF;
}

.notification-batch {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fc5454;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    color: white;
}

.pending-batch-line {
    width: 20px;
    height: 5px;
    background-color: #fc5454;
    position: absolute;
    left: 10px;
    top: 40px;
}

.pending-notification-title {
    font-size: 14px;
    text-align: left;
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: black;
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.result-list .row {
    border-radius: 10px;
}

.result-list [class^="col"] {
    padding: 16px;
}

.result-list .question-list {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

ul.qb-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.qb-list>li {
    border-radius: 10px;
    padding: 0px 10px;
}

ul.qb-list li:nth-child(odd) {
    background-color: #F0EFEF;
}

.result-list .row {
    border-radius: 10px;
}

.result-list [class^="col"] {
    padding: 20px;
}

/* CUSTOM CHECKBOX - RADIO */
.checkbox label:after,
.radio label:after {
    content: '';
    /* display: table; */
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    /* border-radius: .25em; */
    width: 25px;
    height: 25px;
    line-height: 1.5;
    margin-right: 1rem;
    text-align: center;
    vertical-align: unset;
    color: #A0A0A0;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    line-height: 0;
    top: 50%;
    left: 13%;
}

.radio .cr>i {
    margin-left: .5px;
}

.checkbox label,
.radio label {
    margin-bottom: 0px;
}

.checkbox input,
.radio input {
    display: none;
}

.checkbox label input[type="checkbox"]+.cr>i,
.radio label input[type="radio"]+.cr>i {
    opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>i,
.radio label input[type="radio"]:checked+.cr>i {
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr,
.radio label input[type="radio"]:disabled+.cr {
    opacity: .5;
}

.poll-sidebar a:hover {
    text-decoration: none;
}

@-webkit-keyframes wait {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes wait {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 355, 0.3), 0 0 0 .6em rgba(255, 255, 355, 0.3);
    }

    100% {
        box-shadow: 0 0 0 .4em rgba(255, 255, 355, 0.3), 0 0 0 .8em rgba(255, 255, 355, 0.3), 0 0 0 .9em rgba(101, 255, 120, 0);
    }
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 355, 0.3), 0 0 0 .6em rgba(255, 255, 355, 0.3);
    }

    100% {
        box-shadow: 0 0 0 .4em rgba(255, 255, 355, 0.3), 0 0 0 .8em rgba(255, 255, 355, 0.3), 0 0 0 .9em rgba(101, 255, 120, 0);
    }
}

/* RESPONSIVE CSS */
@media (max-width: 767px) {
    ul.anslist>li:hover {
        width: 100%;
    }
}

.p_textareaOptions_div {
    display: none;
}

/**** Create Poll START ****/
.poll_head_div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

/*
.poll_dt_div {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
*/

.poll_dt_lbl {
    width: 100px;
    margin-bottom: 0px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.poll_dt_div .input-group-addon {
    background-color: #ffffff;
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
}

.poll_dt_div .p_enddate {
    background-color: #FFFFFF;
}

.poll_dt_div .p_enddate:focus {
    background-color: #FFFFFF;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    cursor: not-allowed !important;
}

.p_editable_div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.radio_circle {
    border: 1px solid #a9a9a9;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: block;
}

.pollAns_action_div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.c_pollAns_div {
    margin-bottom: 0.75rem;
    padding: 5px 10px;
}

.c_pollAns_div .pollAns_action_div .dlt_svg,
.c_pollAns_div .pollAns_action_div .copy_svg {
    display: none;
}

.c_pollAns_div:hover .pollAns_action_div .dlt_svg,
.c_pollAns_div:hover .pollAns_action_div .copy_svg {
    display: block;
}

.c_pollAns_div:hover {
    background-color: #f5f5f5;
    border-radius: 2rem;
}

.c_pollAns_div:hover input {
    background-color: #f5f5f5;
}

.eep-svg-hover:hover svg path.path_bg,
.eep-svg-hover:hover svg path.path_border {
    fill: #f4f4f4;
}

.eep-svg-hover:hover svg path.path_action {
    fill: #848484;
}

.c_pollAddmore_div,
.dlt_svg,
.copy_svg {
    cursor: pointer;
}

.p_addmore_lbl {
    padding: 0.375rem 0.75rem;
    cursor: pointer;
}

.c_pollAns_whole_div .c_pollAns_div:nth-child(1) .pollAns_action_div .dlt_svg,
.c_pollAns_whole_div .c_pollAns_div:nth-child(2) .pollAns_action_div .dlt_svg,
.pollTopDiv.optionReachedMaximum .c_pollAns_whole_div .c_pollAns_div .pollAns_action_div .copy_svg,
.pollTopDiv.optionReachedMaximum .c_pollAddmore_whole_div .c_pollAddmore_div {
    display: none;
}

.c_pollAns_whole_div {
    display: flex;
    flex-direction: column;
    width: 350px;
}

.pollTopDiv .c_pollAddmore_whole_div .c_pollAddmore_div {
    display: block;
    padding: 5px 10px;
}

.eep_p_assignto label {
    font-size: 14pt;
}
/**** Create Poll END ****/

/**** ANswer Poll START ****/
.poll-area label {
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 8px 15px 8px 0px;
    transition: all 0.2s ease;
  }
  
  .poll-area label:hover {
    border-color: #ddd;
  }
  
  .poll-area label.selectall {
    pointer-events: none;
  }
  
  .poll-area label .row {
    display: flex;
    pointer-events: none;
    justify-content: space-between;
  }
  
  .poll-area label .row .column {
    display: flex;
    align-items: center;
  }
  
  .poll-area label .row .circle {
    height: 19px;
    width: 19px;
    display: block;
    border: 1px solid #707070;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
  }
  
  .poll-area label.selected .row .circle {
    border-color: #6665ee;
  }
  
  .poll-area label .row .circle::after {
    content: "";
    height: 11px;
    width: 11px;
    background: #6665ee;
    border-radius: inherit;
    position: absolute;
    left: 3px;
    top: 3px;
    display: none;
  }
  
  .poll-area label:hover .row .circle::after {
    display: block;
    background: #646464;
  }
  
  .poll-area label.selected .row .circle::after {
    display: block;
    background: #646464 !important;
  }
  
  .poll-area label .row span {
    font-size: 16px;
    font-weight: 500;
  }
  
  .poll-area label .progress {
    height: 7px;
    width: 100%;
    position: relative;
    background: #f0f0f0;
    margin: 8px 0 3px 0;
    border-radius: 30px;
    pointer-events: none;
  }
  
  .poll-area label .progress:after {
    position: absolute;
    content: "";
    height: 100%;
    background: #ccc;
    width: calc(1% * var(--w));
    border-radius: inherit;
    transition: all 0.2s ease;
  }
  
  .poll-area input[type="radio"],
  .poll-area input[type="checkbox"] {
    display: none;
  }
  
  .poll-area .eep_progress_div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  
  .poll-area .eep_progress_div span.percent {
    padding-left: 20px;
  }

/**** ANswer Poll END ****/