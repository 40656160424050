.attachment_toggle_feedback {
    .attachement-display-flex {
        background-color: #F6F6F6
    }

    .attachment_image_size {
        width: 60px !important;
        max-height: 60px !important;
        height: 60px !important;
        border-radius: 8px;
        object-fit: cover;
        margin-right: 8px;
        opacity: 0.5;
        border: 2px solid #9E9E9E;
    }

    .attachment_parent {
        position: relative;
    }
}

.right_profile_div_r-feed {
    background-color: #fff !important;
    border: 1px solid #EAEAEA;
    //     z-index: 10 !important;
    //     height: 50px;
    //     padding: 0px 10px;

    //     .ideabox-profile-expand-container {
    //         align-items: center;
    //     }
}

.item_blog_like_a_feedback_ {
    margin-left: 30px;

    .heartCount {
        font-size: 13px;
    }

    .post_heart {
        width: 12px;
        height: 11px;
        margin-bottom: 2px;
    }
}

.item_blog_like_a_feedback {
    .parent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
    }

    .emoji {
        width: auto;
        padding: 4px;
        background: #fff;
        border-radius: 6px;
        margin-bottom: 14px;
        height: 30px;
        display: flex;
        margin-right: 4px;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #80808045;
    }

    .c1 {
        background: #fff;
        padding: 6px;
        border-radius: 4px;
        // position: relative;
        // height: max-content;

        .EmojiPickerReact {
            margin-top: 30px;
            //     height: 300px !important;
            //     width: 350px !important;
            position: absolute !important;
            //     z-index: 10000;
            //     bottom: 30px;
        }
    }

    .c2 {
        margin-left: 6px;
    }
}

.ideacontent_heading_feedback {
    margin: 10px;
}

.msg_content {
    background: #F6F6F6;
    margin: 10px;
    border-radius: 6px;
    width: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.feedback-profile-img-size {
    height: 30px;
    margin-left: 10px;
    margin-top: 8px;
}

.attachment_parent_feedback_more {
    height: 56px;
    width: 56px;
    background-color: #0000009e;
    border-radius: 10px;
    color: #fff;
    margin-top: -10px;

    a {
        align-items: center;
        justify-content: center;
        display: flex;
        height: 100%;
        cursor: pointer;
    }
}

.feedback-download-profile-img-size {
    position: absolute;
    background: #fff;
    right: 12px;
    top: 4px;
    border-radius: 4px !important;
    padding: 2px;
    z-index: 1;
}

.modal-content-f {
    .birthdy_div {

        .slick-prev:before,
        .slick-next:before {
            color: #000 !important;
        }

        .parent_slider_img {
            border: 2px solid #9e9e9e3d;
            width: 98% !important;
        }
    }
}

.slick-prev:before,
.slick-next:before {
    color: #000 !important;
    font-size: 23px;
}

.replay {
    text-align: right;
    cursor: pointer;
    color: #244AC4;
}

.ideaCommentListsChild-f {
    margin-bottom: 0px;
    display: flex;
    padding: 10px;
    padding-bottom: 0px;
    // align-items: center;

    .eep_command_attachements_ {
        margin-left: 30px;
    }

    .ideabox-profile-img-size {
        width: 26px;
        height: 26px;
        box-shadow: none;
    }

    .ideabox-profile-img-size_ {
        margin-right: 4px;
        margin-top: -6px;
    }

    .reply_user_name {
        font-size: 12px;
        margin-left: 30px;
    }

    .eep_command_posts {
        background: #fff;
        padding: 6px;
        border-radius: 0px 10px 10px 10px;
        width: auto;
        margin: 0px !important;
        margin-bottom: 2px !important;
    }
}

.ideabox-message-textarea-f {
    height: 30px !important;
}

.reply-textarea-inner-f {
    width: 96% !important;
    float: right;
    padding: 10px;
}

// .ideashorting_div_child {
// }

.funtional_parts-f {
    // display: flex;
    justify-content: right;
}

.ideabox-date-f {
    // position: absolute;
    width: max-content;
    font-size: 12px;
    margin-top: 6px;
    // right: 4px;
    // bottom: 4px;
}

.head {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ideabox-profile-container-f {
    height: 76px;
    // margin-top: 10px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;

    // .ideabox_user_name_size {
    //     color: #403C47;
    //     font-size: 15px;
    // }

    .ideabox_contentt_size {
        color: #717074;
        font-weight: 100;
    }
}

.EmojiPickerReact {
    position: absolute !important;
}

.to_show_pdf_list {
    width: 70px;
    border: 1px solid rgba(158, 158, 158, 0.2901960784);
    border-radius: 8px;
    height: 32px;
    display: flex;
    background: rgba(158, 158, 158, 0.5098039216);
    margin-left: 4px;
    margin-top: 2px;
    margin-right: 8px;

    img {
        width: 30%;
        margin: auto;
    }
}

.replayIconSize {
    height: 40px;
    max-width: 50px;
    width: auto;
    border-radius: 4px;
    border: 1px solid #9e9e9e45;
}

.card-body-feedback {
    padding: 0px 22px;
}

.image_chat {
    width: 30px;
    height: 30px;
    background: #fff;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    border: 1px solid #9e9e9e45;
    padding: 1px;
    margin: 10px 0px 0px 10px;
    display: flex;
    border-radius: 50%;
}
.searchfeed{
    & input {
        background: #fff;
        border: 2px solid #EAEAEA !important;
    }
}
.nodata{
        display: flex;
        justify-content: center;
        align-items: center; 
        height: 50vh; 
}
.feedback-listview {
    // border: 2px solid #EAEAEA;
    border-radius: 8px;
    padding: 14px 0px;
    height: 100vh;
    overflow-x: hidden;
    .underline {
        height: 2px !important;
        background: #EAEAEA !important;
        width: 100% !important;
    }
    .fixed-filter-feedback {}

    .three_dot {
        .eep_custom_dropdown_bg {
            background: #FFFFFF;
            box-shadow: 0px 1px 6px #0000002B;
            padding: 6px !important;

            .dropdown-item {
                padding: 6px !important;
            }
        }
    }

    .eep_select_common_sort {
        font-weight: bold;
    }

    .feedback-search-value {
        padding:0px 10px;
        // border-bottom: 2px solid #EAEAEA !important;

      

        .form-check-label {
            opacity: 0.6 !important;
            width: max-content;
        }

        .form-check-input {
            margin: auto !important;
            position: unset;
        }

        & input {
            background: #fff;
            border: 2px solid #EAEAEA !important;
        }
    }

    // .ideashorting_div {
    //     border-top: 2px solid #EAEAEA !important;
    // }


    .ideashorting_div_child {
        // background-color: #ffff;
        // align-items: center;
        // border: none;
        // border-radius: 12px;
        // border-bottom: 0px solid #EAEAEA !important;
        // opacity: 1;

        .funtional_parts {
            display: flex;
            justify-content: end;
        }
    }

    // .ideaMarkedAsRead {
    //     background-color: #F9f9f9;
    //     // opacity: 0.9;
    //     .idebox-active-navigation{
    //         background-color: #f1f1f1;
    //     }
    // }
}     

.feedback_arrow_div {
    width: 60%;

    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        font-weight: bold;
        font-size: 15px;
    }

    .i_position {
        margin-right: 16px;
    }
}

#parentElement {
    p:first-child {
        margin: 0;
    }
}

.view-more-line {
    cursor: pointer;
    margin-top: 12px;

    & div {
        width: 40px;
        height: 2px;
        background: #9e9e9e4f;
        margin-right: 10px;
    }

    display: flex;
    align-items: center;
    margin-left: 34px;
    font-size: 12px;
}

.feed-comment-close {
    float: right;
    position: absolute;
    right: 4px;
    background: #fff;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #878da2;
}

.replay-comment {
    border-radius: 8px;
    font-style: italic;
    background-color: #fff;
    padding: 10px;
}
.nh{
    min-height: 100%;
}