.bg-area {
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
}

.bg-circles {
  background: #e4e4e4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bg-circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  /*     background: rgba(255, 255, 255, 0.2); */
  animation: animate 25s linear infinite;
  bottom: -150px;
  // background-image: url("https://enliteu.com/wp-content/uploads/2022/10/EnliteU-Fav.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.bg-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
  background-image: url("../../../public/images/icons/login/awards.svg");
}


.bg-circles li:nth-child(2) {
  left: 4%;
  width: 120px;
  height: 120px;
  animation-delay: 2s;
  animation-duration: 12s;
  background-image: url("../../../public/images/icons/login/badges.svg");
}

.bg-circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
  background-image: url("../../../public/images/icons/login/certificates.svg");
}

.bg-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
  background-image: url("../../../public/images/icons/login/e-cards.svg");
}

.bg-circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  background-image: url("../../../public/images/icons/login/Forum.svg");
}

.bg-circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
  background-image: url("../../../public/images/icons/login/ideabox.svg");
}

.bg-circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
  background-image: url("../../../public/images/icons/login/polls.svg");
}

.bg-circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
  background-image: url("../../../public/images/icons/login/survey.svg");
}

.bg-circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
  background-image: url("../../../public/images/icons/login/EnliteU-Fav.svg");
}

.bg-circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
  background-image: url("../../../public/images/icons/login/EnliteU-Fav.svg");
}

@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }

}